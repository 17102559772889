.AIAssistanceAns_page {
    justify-content: inherit !important;
    min-height: inherit !important;

    .AIAssistanceAns_content{
        margin-top: 20px;
        width: 90%;
    }
    textarea.form-control {
        height: 280px !important;
    }
}