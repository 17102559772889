@media screen and (max-width: 1650px) {
    .subscription_plan .subscription_plantitle .plan_icon {
        width: 70px;
        height: 70px;
    }

    .subscription_plan h3 {
        font-size: 18px;
    }

    .subscription_planlist ul li {
        font-size: 13px;
        line-height: 18px;
    }

    .select_plan {
        padding: 12px 45px;
    }
}

@media screen and (max-width: 1400px) {
    .subscription_plan {
        width: calc(25% - 19px);
    }

    .subscription_plan h3 {
        font-size: 16px;
    }

    .login-box-right {
        padding: 15px 36px;

        h2 {
            margin: 20px 0 0;
        }

        .info-box {
            padding: 8px 20px;
        }
    }

    .otp-graphics img {
        max-width: 100px;
    }
}

@media (min-width: 1201px) and (max-width: 1366px) {
    .left-part-bp {
        width: 300px;
    }

    .main-part-bp {
        // margin: 0 auto;
        // max-width: 90%;
        // min-height: 780px;
        // padding-left: 260px;
        width: calc(100% - 320px);
        margin-left: 320px;
    }

    .left-menu>h3 {
        font-size: 18px;
        line-height: 26px;
    }
}

@media screen and (max-width: 1200px) {
    .subscription_plan {
        width: calc(33.3333% - 19px);
    }

    .subscribe_content .nav-tabs .nav-link {
        font-size: 18px;
    }

    .brand_managementtitle h5 {
        font-size: 16px;
    }

    .brand_management_plan {
        min-height: 400px;
    }

    /* 08-08-2024 ARPAN */
    .login-box {
        max-width: 90%;
    }

    .login-box-left {
        width: 50%;
    }

    .login-box-right {
        width: 50%;
    }

    .left-part-bp {
        width: 85px;
        z-index: 1;
        overflow: hidden;
    }

    .left-menu>h3 {
        display: none;
    }

    .main-part-bp {
        // padding: 0 15px 0 100px;
        width: calc(100% - 85px);
        margin-left: 95px;
    }

    .left-menu {
        padding: 0 20px;
    }

    /* 08-08-2024 ARPAN */
}

@media (min-width:768px) and (max-width:991px) {
    .subscription h2 {
        font-size: 36px;
    }

    .subscription p {
        font-size: 16px;
        line-height: 24px;
    }

    .subscription p br {
        display: none;
    }

    .subscription_plan {
        padding: 15px 10px 92px 10px;
    }

    .subscription_planlist ul li {
        font-size: 11px;
    }

    .subscription_plan h3 {
        font-size: 15px;
    }

    .brand_managementtitle h5 {
        font-size: 13px;
    }

    .brand_management_plan {
        min-height: 500px;
    }

    /* home start */
    .navbar-toggler {
        border: none;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .navigation .navbar .navbar-nav {
        gap: 12px;
        padding-left: 0px;
    }

    .navigation .navbar ul li a {
        padding: 0;
    }

    .navigation .navbar-collapse {
        margin: 25px 0 0 0;
        padding-left: 15px;
    }

    .login {
        margin-top: 15px;
    }

    .banner-text span {
        font-size: 40px;
    }

    .banner-text h1 {
        font-size: 60px;
        font-weight: 600;
        margin: 0 0 20px 0;
    }

    .banner-text p {
        font-size: 24px;
    }

    .xl-orng-btn {
        font-size: 25px;
    }

    .sub-heading.pt-120 {
        padding-top: 0;
    }

    .t1 {
        transform: translateY(0px) !important;
    }

    .inner-all-boxes .inner-boxes {
        width: 50%;
    }

    .inner-all-boxes {
        gap: 15px;
        margin-top: 30px;
    }

    .master-orng-btn.mt-40 {
        margin-top: 20px;
    }

    .t2:hover {
        transform: translateY(0px) !important;
    }

    .faq-img img {
        width: 100%;
    }

    .faq-img {
        margin-bottom: 50px;
    }

    .sub-heading.mb-50 {
        margin-bottom: 30px;
    }

    .footer-logo ul li {
        padding: 5px 4px 0px 4px;
        line-height: 0;
    }

    .footer-logo ul {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .footer-link {
        padding-left: 25px;
        padding-bottom: 25px;
    }

    /* home end */
    /* about start */
    .get-started h2 {
        font-size: 35px;
    }

    .get-started h3 {
        font-size: 24px;
    }

    .master-orng-btn {
        font-size: 18px;
    }

    /* about end */
    /* payment-method start */
    .payment-box {
        min-height: 800px;
        max-height: 800px;
    }

    .env-box span {
        font-size: 15px;
    }

    .cancel-btn {
        left: 0;
        top: -63px;
        border-radius: 0;
    }

    /* payment-method end */
    /* 08-08-2024 ARPAN */
    .profile-box-data {
        flex-direction: column;
        padding: 15px;
    }

    .profile-box-left {
        width: 100%;
        flex-direction: column;
    }

    .profile-box-right {
        width: 100%;
    }

    .profile-box-right .col-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .left-part-bp {
        width: 85px;
        z-index: 1;
        overflow: hidden;
    }

    .left-menu>h3 {
        display: none;
    }

    // .main-part-bp {
    //     padding: 0 15px 0 100px;
    // }

    .left-menu {
        padding: 0 20px;
    }

    .login-box {
        max-width: 90%;
    }

    .login-box-left {
        width: 50%;
    }

    .login-box-right {
        width: 50%;
    }

    /* 08-08-2024 ARPAN */

    .brand_managementtitle h5 {
        font-size: 11px;
    }

    .brand_management_plan {
        min-height: inherit;
    }

    .profile-box-right {
        padding: 30px 0;
    }

    .footer-logo ul li {
        padding: 5px 2px 0;
    }

    .get-started h2 {
        font-size: 28px;
    }
    .otp-form button{
        width: inherit;
    }
}

@media (max-width: 767px) {
    .subscription_plan {
        width: calc(50% - 19px);
    }

    .subscribe_content .nav-tabs .nav-link {
        font-size: 11px;
        padding: 8px 12px;
    }

    .subscription h2 {
        font-size: 30px;
    }

    .subscription p {
        font-size: 14px;
        line-height: 20px;
    }

    .subscription p br {
        display: none;
    }

    .brand_management_plan {
        min-height: inherit;
    }

    .login-box {
        max-width: 90%;
        flex-direction: column;
    }

    .login-box-left {
        width: 100%;
        min-height: 50vh;
    }

    .login-box-right {
        width: 100%;
    }

    .login-box-right h2 {
        margin: 0;
    }

    .form-wrap {
        margin-top: 60px;
    }

    .login-text {
        margin: 50px 0 0;
    }

    .logo-login img {
        max-width: 160px;
    }

    .login-box-right {
        background-size: 100%;
    }

    .otp-graphics img {
        display: none;
    }

    .info-box {
        font-size: 12px;
    }

    .login-text h2 {
        font-size: 24px;
        margin: 0 0 10px;
    }

    .login-text p {
        font-size: 14px;
    }

    .login-text.otp-txt {
        margin: 0 0 0;
    }

    .sign-up-box h3 {
        font-size: 12px;
        margin: 0 0 5px;
    }

    .otp-graphics {
        margin: 10px auto 15px;
    }

    .otp-form input {
        letter-spacing: 5px;
    }

    .otp-form input::placeholder {
        letter-spacing: 5px;
    }

    .subscription_plan {
        width: calc(50% - 19px);
    }

    .subscribe_content .nav-tabs .nav-link {
        font-size: 11px;
        padding: 8px 12px;
    }

    .subscription h2 {
        font-size: 30px;
    }

    .subscription p {
        font-size: 14px;
        line-height: 20px;
    }

    .subscription p br {
        display: none;
    }

    .brand_management_plan {
        min-height: inherit;
    }

    /* home start */
    .navbar-toggler {
        border: none;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .navigation .navbar .navbar-nav {
        gap: 12px;
        padding-left: 0px;
    }

    .navigation .navbar ul li a {
        padding: 0;
    }

    .navigation .navbar-collapse {
        margin: 25px 0 0 0;
        padding-left: 15px;
    }

    .login {
        margin-top: 15px;
    }

    .banner {
        padding: 90px 0 90px 0;
    }

    .banner-text span {
        font-size: 25px;
    }

    .banner-text h1 {
        font-size: 40px;
        font-weight: 600;
        margin: 0 0 20px 0;
    }

    .banner-text p {
        font-size: 16px;
    }

    .xl-orng-btn {
        font-size: 18px;
        padding: 18px 45px;
    }

    .sub-heading.pt-120 {
        padding-top: 0;
    }

    .t1 {
        transform: translateY(0px) !important;
    }

    .inner-all-boxes .inner-boxes {
        width: 100%;
    }

    .inner-all-boxes {
        gap: 15px;
        margin-top: 30px;
        flex-direction: column;
    }

    .master-orng-btn.mt-40 {
        margin-top: 20px;
    }

    .master-orng-btn {
        padding: 15px 25px;
        font-size: 15px;
    }

    .t2:hover {
        transform: translateY(0px) !important;
    }

    .faq-img img {
        width: 100%;
    }

    .faq-img {
        margin-bottom: 50px;
    }

    .sub-heading.mb-50 {
        margin-bottom: 30px;
    }

    .footer-logo ul li {
        padding: 5px 4px 0px 4px;
        line-height: 0;
    }

    .footer-logo ul {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .footer-link {
        padding-left: 25px;
        padding-bottom: 25px;
    }

    .body-part.mt-5 {
        margin-top: 0 !important;
    }

    .sub-heading h2 {
        font-size: 30px;
    }

    .work-box h3 {
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 15px 0;
    }

    .work-box p {
        font-size: 14px;
        line-height: 20px;
    }

    .faq-accordian .accordion-button {
        font-size: 16px;
    }

    .faq-accordian .accordion-body {
        font-size: 14px;
    }

    .footer-logo {
        margin-bottom: 30px;
    }

    .work-box {
        margin: 15px 0;
    }

    /* home end */
    /* about start */
    .get-started h2 {
        font-size: 35px;
    }

    .get-started h3 {
        font-size: 24px;
    }

    .about-text h2 {
        font-size: 28px;
        line-height: 38px;
    }

    .inner-page-banner-size {
        padding: 40px 0 60px 0;
    }

    .sub-heading-inner h2 {
        font-size: 30px;
    }

    .guide-img {
        margin: 20px 0 40px 0;
    }

    .section-para h2 {
        margin: 0 0 25px 0;
        font-size: 35px;
    }

    .mob-flip {
        flex-direction: column-reverse;
    }

    .history-sec .pe-5,
    .history-sec .ps-5 {
        padding: calc(var(--bs-gutter-x)* .5) !important;
    }

    .section-para-img img {
        margin-bottom: 30px;
    }

    .history-sec .mb-50 {
        margin-bottom: 0;
    }

    .history-sec {
        padding: 70px 0 180px 0;
    }

    .get-started-color .get-started h2 {
        font-size: 24px;
    }

    .get-started-color .get-started h3 {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .get-started {
        padding: 30px;
    }

    /* about end */
    /* contact start */
    .contact-form {
        margin-top: 40px;
    }

    /* contact end */
    /* payment-method start */
    .env-box span {
        font-size: 15px;
    }

    .payment-box {
        flex-direction: column;
        overflow-y: auto;
    }

    .payment-left-box,
    .payment-right-box {
        width: 100%;
    }

    .cancel-btn {
        padding: 0px 0px;
        position: static;
    }

    .summery-logo {
        margin-top: 10px;
    }

    /* payment-method end */
    /* thank you start */
    .thank-you-text img {
        margin: 0 0 30px 0;
        max-width: 40%;
    }

    .thank-you-text h1 {
        font-size: 30px;
    }

    .thank-you-text p {
        font-size: 15px;
    }

    .thank-you-text .master-orng-btn {
        margin: 34px 0 0 0;
        padding: 20px 60px;
    }

    .cont-txt h4 {
        font-size: 14px;
    }

    .cont-sosial-links ul li a {
        font-size: 18px;
    }

    .thank-you-page {
        min-height: 640px;
        max-height: 640px;
    }

    /* thank you end */
    /* 08-08-2024 ARPAN */
    .profile-box-data {
        flex-direction: column;
        padding: 15px;
    }

    .profile-box-left {
        width: 100%;
        flex-direction: column;
    }

    .profile-box-right {
        width: 100%;
    }

    .profile-box-right .col-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .left-part-bp {
        width: 75px;
        z-index: 1;
        overflow: hidden;
    }

    .left-menu>h3 {
        display: none;
    }

    // .main-part-bp {
    //     padding: 0 15px 0 90px;
    // }

    .main-part-bp .col-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .left-menu {
        padding: 0 15px 0 15px;
    }

    .main-part-bp .mb-0.float-input {
        margin-bottom: 20px !important;
    }

    .float-input .form-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        width: inherit;
        padding: 3px 10px;
        max-width: 50vw;
    }

    .btn-wrap-bp button {
        width: 120px;
    }

    /* 08-08-2024 ARPAN */


    .navigation .navbar {
        padding: 10px 0;
    }

    .work-box img {
        margin: 0 auto 22px auto;
    }

    .footer-logo .form-select {
        max-width: inherit;
    }

    .managementplan_ctrl .owl-carousel.mb-5>div {
        max-width: inherit;
    }

    .page-title {
        font-size: 28px;
    }

    // .main-part-bp {
    //     padding: 0 12px 0 85px;
    // }

    .white-box-wrap {
        padding: 26px 12px;
    }

    .bp-accordion .accordion-button {
        font-size: 12px;
        line-height: normal;
        padding: 10px;
    }

    .bp-accordion .accordion-body {
        padding: 10px;
    }

    .bp-accordion .container-check,
    .bp-accordion .container-radio {
        font-size: 12px;
    }

    .profile-box-data .table {
        min-width: 700px;
    }

    .footer-link {
        padding-bottom: 0px;
        padding-left: 25px;
    }



    .sub-heading h2 {
        font-size: 28px;
    }

    .login-text {
        margin: 0px 0 0;
    }

    .login-box-left {
        padding: 15px;
        height: 50vh;
    }

    .login-box-right .form-wrap {
        margin-top: 0;
    }

    .login-box-right h2,
    .login-box-right h2 span {
        font-size: 24px;
    }

    .login-box {
        margin: 30px auto;
        max-height: inherit; 
    }

    .managementplan_ctrl .owl-carousel.mb-5>div {
        max-width: inherit;
        width: 100%;
    }

    .main-part-bp {
        margin-left: 95px;
        width: calc(100% - 110px);
    }

    .float-input .form-control {
        font-size: 13px;
    }

    .float-input .form-label {
        font-size: 11px;
    }

    .container-radio.me-5 {
        margin-right: 20px !important;
    }

    .contact-banner {
        background: #f3f8fe !important;
    }
    .profile-box-title{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start !important;
        gap: 5px;
    }
}

@media screen and (max-width:480px) {
    .subscribe_content .nav-tabs .nav-link {
        font-size: 10px;
        padding: 5px 7px;
    }

    .subscription_plan {
        width: 100%;
    }
}

@media (max-width: 980px) {
    .plan-craft-table th {
        white-space: nowrap;
    }

    .chat_heading {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .chat_content_details {
        padding: 0 15px;
    }
}