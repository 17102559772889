.modal{
    opacity: 1; 
    z-index: 9998;
}
.modal-content{
    top: 80px;
}
.solid-btn:hover{
    background-color: #b77006;
    border: 1px solid #b77006;
}
.outline-btn:hover{
    background-color: white;
    border: 1px solid #FF9E0C;
}