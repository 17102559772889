.chat_heading {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;

    h3 {
        padding: 0 0 0px 0;
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: var(--orange-color);
    }

    p {
        padding: 8px 0 0 0;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: #000;

        span {
            color: #9d9d9d;
        }

        a {
            color: var(--orange-color);
            text-decoration: none;
        }
    }
}

.chat_headingdescription {
    flex-grow: 1;
}

.close_case a {
    background: var(--orange-color);
    padding: 7px 15px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
    display: inline-flex;
}

.close_case a.closed_case {
    background: #c5d82d;
}

.chat_description {
    padding: 15px 0;
    border-bottom: 1px solid #eee;

    h3 {
        padding: 0 0 0px 0;
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: var(--orange-color);
    }

    p {
        padding: 5px 0 0 0;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: #000;
    }
}

.chat_comments {
    padding: 15px 0;
    border-bottom: 1px solid #eee;

    h3 {
        padding: 0 0 8px 0;
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: var(--orange-color);
    }
}

.comment_btn {
    background: var(--orange-color);
    padding: 7px 15px; 
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border-radius: 5px;
    display: inline-flex;
    border: 0;
    cursor: pointer;height: 36px;
}

.comment_area {
    margin: 10px 0;
    display: flex;
} 
.comment_arearight {
    flex-grow: 1;
    min-width: 25%;
    max-width: 60%;
    padding: 5px 15px;
    border-radius: 5px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 15px;
        right: 100%;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        transform: translateY(-50%);
    }

    h4 {
        padding: 0 0 5px 0;
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: #000;
    }

    .comment_name {
        padding: 0 0 5px 0;
        margin: 0;
        font-size: 15px;
        font-weight: 600; 
    }

    .file_icon {
        width: 30px;
        height: 30px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 50px;
        font-size: 18px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .comment_date {
        font-size: 12px;
        font-weight: 300; 
    }

    p {
        padding: 0 0 8px 0;
        margin: 0;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #424242;
    }
}


.comment_area.user {
    .comment_arealeft {
        width: 40px;
        order: 2
    }

    .comment_arearight {
        margin-left: auto;
        background: #ffdaa2;
        margin-right: 20px;
        flex-grow: inherit;

        &::after {
            left: 100%;
            right: auto;
            border-left: 7px solid #ffdaa2;
        }
    }
}

.comment_area.agent {

    // Admin
    .comment_arearight {
        background: #dfe7a1;
        flex-grow: inherit;

        &::after {
            border-right: 7px solid #dfe7a1;
        }

    }
}

.comment_arealeft {
    width: 60px;
    flex-shrink: 0;
}

.comment_arealeftimg {
    width: 40px;
    height: 40px;
    background: var(--green-color);
    border-radius: 100px;
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        border-radius: 100px;
    }
}

textarea.form-control {
    resize: none;
    height: 80px;
}

.download_icon {
    width: 30px;
    height: 30px;
    background: #c5d82d;
    border-radius: 100px;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat_content {
    padding: 100px 0 0 0;
    background: #f9f9f9;
}

.chat_content_details {
    padding: 0 35px;
    background: #fff;
}




// / CHAT PAGE END /